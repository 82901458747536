import { render, staticRenderFns } from "./CreateCar.vue?vue&type=template&id=48fdeeaa&scoped=true&"
import script from "./CreateCar.vue?vue&type=script&lang=js&"
export * from "./CreateCar.vue?vue&type=script&lang=js&"
import style0 from "./CreateCar.vue?vue&type=style&index=0&id=48fdeeaa&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "48fdeeaa",
  null
  
)

export default component.exports